<template>
  <el-select
    :style="{ width: width }"
    size="small"
    v-model="selectValue"
    :loading="isLoading"
    filterable
    remote
    clearable
    :remote-method="getList"
    placeholder="请选择或输入关键字"
  >
    <el-option v-for="item in list" :label="`${item.username}-${item.level_dsc}`" :value="item.id" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getLawPeopleListAPI } from './api.js'
export default {
  name: 'SelectLawPeople',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '400px'
    },
    search_area_id: {
      type: [Number, String],
      default: ''
    },
    level: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    selectValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    // this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        page_size: 20,
        username: query,
        user_type: 1,
        search_area_id: this.search_area_id,
        level: this.level
      }
      let res = await getLawPeopleListAPI(params)
      this.list = res.data
    }
  }
}
</script>

<style></style>
