<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form class="addForm" ref="form" :model="formData" label-width="120px">
      <admin-title title="申请人信息"></admin-title>

      <el-form-item label="姓名（单位）" prop="apply_username" :rules="rules">
        <el-input v-model="formData.apply_username" size="small"></el-input>
      </el-form-item>
      <el-form-item
        label="证件号"
        prop="apply_idcard"
        :rules="[
          { required: true, message: '该项不能为空', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号不正确', trigger: 'blur' }
        ]"
      >
        <el-input v-model="formData.apply_idcard" size="small"></el-input>
      </el-form-item>
      <el-form-item
        label="联系号码"
        prop="apply_mobile"
        :rules="[
          { required: true, message: '该项不能为空', trigger: 'blur' },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号不正确', trigger: 'blur' }
        ]"
      >
        <el-input v-model="formData.apply_mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="apply_address" :rules="rules">
        <el-input v-model="formData.apply_address" size="small"></el-input>
      </el-form-item>

      <admin-title title="对方当事人信息"></admin-title>

      <el-form-item label="姓名（单位）">
        <el-input v-model="formData.opposite_username" size="small"></el-input>
      </el-form-item>
      <el-form-item
        label="证件号"
        prop="opposite_idcard"
        :rules="[{ pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号不正确', trigger: 'blur' }]"
      >
        <el-input v-model="formData.opposite_idcard" size="small"></el-input>
      </el-form-item>
      <el-form-item
        label="联系号码"
        prop="opposite_mobile"
        :rules="[
          { required: true, message: '该项不能为空', trigger: 'blur' },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号不正确', trigger: 'blur' }
        ]"
      >
        <el-input v-model="formData.opposite_mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="formData.opposite_address" size="small"></el-input>
      </el-form-item>

      <admin-title title="调解内容"></admin-title>

      <el-form-item label="纠纷内容">
        <el-input
          style="width: 400px"
          type="textarea"
          rows="4"
          v-model="formData.mediate_content"
          show-word-limit
          :maxlength="500"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="附件资料">
        <UploadImgs :background_images.sync="imgs"></UploadImgs>
        <div class="tip">（附件最多可上传2张）</div>
      </el-form-item>

      <admin-title title="投递单位"></admin-title>
      <el-form-item label="投递区域" prop="handle_area_detail" :rules="rules">
        <SelectArea v-model="formData.handle_area_detail.area_ids"></SelectArea>
      </el-form-item>
      <el-form-item label="调解员">
        <SelectLawPeople ref="law" v-model="formData.handle_law_people_id" :level="30" :search_area_id="search_id"></SelectLawPeople>
      </el-form-item>
      <el-form-item label="录入时间">
        <el-date-picker v-model="formData.tiaojie_time" type="datetime" placeholder="选择日期时间" size="small" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { detailAPI, editAPI, createAPI } from './api'
import { dict_case__status, dict_mediate__status } from '@/enum/dict.js'
import UploadImgs from '@/components/uploader/uploader-imgs.vue'
import SelectArea from '@/views/components/select-area-all.vue'
import SelectLawPeople from '@/views/components/select-law-people.vue'
export default {
  name: 'addOrEdit',
  components: { UploadImgs, SelectArea, SelectLawPeople },

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        dispute_type_id: '',
        handle_status: '',
        apply_username: '',
        apply_mobile: '',
        apply_idcard: '',
        apply_address: '',
        opposite_username: '',
        opposite_mobile: '',
        opposite_address: '',
        mediate_content: '',
        mediate_files: [],
        handle_area_detail: {
          area_ids: []
        },
        handle_law_people_id: '',
        tiaojie_time: ''
      },
      imgs: []
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  watch: {
    'formData.handle_area_detail.area_ids': {
      handler(newV, oldV) {
        this.$nextTick(() => {
          this.$refs.law.getList()
        })
      }
    }
  },
  computed: {
    search_id() {
      let id = ''
      const { handle_area_detail } = this.formData
      if (handle_area_detail.area_ids) {
        id = handle_area_detail.area_ids[handle_area_detail.area_ids.length - 1]
      }
      return id
    }
  },
  methods: {
    save() {
      this.formData.mediate_files = this.imgs.map((item) => {
        return {
          file_name: item.url,
          status: item.status,
          uid: item.uid,
          name: item.name
        }
      })
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            createAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await detailAPI(this.$route.params.id)
      if (this.formData.handle_law_people_id == 0) {
        this.formData.handle_law_people_id = ''
      }
      this.imgs = this.formData.mediate_files.map((item) => {
        return {
          url: item.file_name,
          status: item.status,
          uid: item.uid,
          name: item.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .addForm {
    .el-form-item {
      .el-input {
        width: 400px;
      }

      .tips {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        line-height: 30px;
      }

      .el-range-separator {
        padding: 0;
      }
    }
  }

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .tip {
    color: #989898;
  }
}
</style>
